<template>
    <row>
        <v-btn v-if="tipoBtn == 1" color="info" small @click="GetPdf()" :loading="cargandoDatos" :disabled="!id">
            <v-icon left small>
                {{ icons.mdiFilePdfBox }}
            </v-icon>
            PDF
        </v-btn>

        <v-tooltip bottom v-else>
            <template v-slot:activator="{ on, attrs }" >
                <a class="pr-1" v-bind="attrs" v-on="on" @click="GetPdf()">
                    <v-icon color="primary">{{ icons.mdiFilePdfBox }}</v-icon>
                </a>
            </template>
            <span>PDF</span>
        </v-tooltip>
        <Pdf ref="pdfRef"></Pdf>
    </row>
</template>
<script>
import AjusteServices from '@/api/servicios/AjusteServices'
import { mdiFilePdfBox } from '@mdi/js'
import { ref } from '@vue/composition-api'
import FuncionesGenerales from '@/funciones/funciones'
import Pdf from '@/components/Pdf.vue'
export default {
    props: {
        id: Number,
        tipoBtn: {
            type: Number,
            default: 1
        }
    },
    components:{
        Pdf
    },
    setup(props) {
        const cargandoDatos = ref(false)
        const pdfRef = ref(null)
        const GetPdf = () => {
            cargandoDatos.value = true
           
            AjusteServices.ConsultarDetalles({ id: props.id })
                .then(response => {
                    console.log(response)
                    if (response.data.estatus == true) {


                        const datoInit = response.data.datos[0]
                        const cuerpoTablaDetalles = []


                        datoInit.ajusteDetalle.forEach(item => {
                            cuerpoTablaDetalles.push(
                                [
                                    { content: item.productoSucursal.producto.barra, styles: { halign: 'left' } },
                                    { content: item.productoSucursal.producto.nombre, styles: { halign: 'left' } },
                                    { content: item.lote ? item.lote.numeroLote : '', styles: { halign: 'left' } }, 
                                    { content: item.existenciaActual, styles: { halign: 'right' } },
                                    { content: item.totalUnidades, styles: { halign: 'right' } },
                                    { content: item.existenciaFinal, styles: { halign: 'right' } },

                                ])
                        })


                        const datosInit = {
                            nombreDocumento: `Ajuste: ${datoInit.numeroDocumento}`,

                            cabecera1: {
                                visible: true
                            },
                            titulo: {
                                visible: true,
                                posicion: "center",
                                texto: "Ajustes",
                                TamanoLetras: 14,
                                color: "#7B7B7B",
                            },
                            cabecera2: {
                                visible: true,
                                datos: [
                                    { texto: "# " + datoInit.numeroDocumento, TamanoLetras: 14, color: "#FF0000" },
                                    { texto: "Fecha Creacion: " + FuncionesGenerales.formatoFecha(datoInit.fechaCreacion, 1) },
                                    { texto: "Hora Creacion: " + FuncionesGenerales.formatoFecha(datoInit.fechaCreacion, 2).replace(/\u00a0/g, "").replace(/\u202f/g, "") },
                                    { texto: "Empleado: " +  datoInit.usuarioCrea.empleado.nombre + ' ' + datoInit.usuarioCrea.empleado.apellido }, 
                                ]
                            },
                            sub1: {
                                visible: true,
                                datos: [

                                    { texto: "Fecha Ajuste: " + FuncionesGenerales.formatoFecha(datoInit.fechaAjuste, 1) },
                                    { texto: "Area: " + datoInit.area.nombre },
                                    { texto: "Tipo Ajuste: " + datoInit.tipoAjuste.descripcion },
                                    { texto: "Observacion: " + datoInit.observacion }
                                   
                                ]
                            },
                            sub2: {
                                visible: true,
                                datos: [
                                    { texto: "Motivo: " + datoInit.motivoAjuste.descripcion },
                                    { texto: "Estatus:" + datoInit.estatus.descripcion },
                                ]
                            },
                            cuerpo1: {
                                visible: true,
                                datos: [
                                    {
                                        titulo: { texto: "Detalles", TamanoLetras: 12, },
                                        styles:   {fontSize: 7,},
                                        columnStyles: {

                                            3: {
                                                halign: 'right'
                                            }
                                        },
                                        tablas: {
                                        },
                                        head: [[

                                            { content: "Barra", styles: { halign: 'left' } },
                                            { content: "Producto", styles: { halign: 'left' } },
                                            { content: "Lote", styles: { halign: 'left' } }, 
                                            { content: "Exi Inicio", styles: { halign: 'right' } },
                                            { content: "Cant Ajustada", styles: { halign: 'right' } },
                                            { content: "Exi Final", styles: { halign: 'right' } },
                                        ]],
                                        body: cuerpoTablaDetalles,

                                    },
                                ]
                            }
                        }




                        pdfRef.value.generarPDF(datosInit)


                    }
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    cargandoDatos.value = false
                })


        }

        return {
            GetPdf,
            cargandoDatos,
            pdfRef,
            icons: {
                mdiFilePdfBox,
            }
        }
    },
}
</script>