//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'

class AjusteServices {


  Actualizar(
    IdUsuario,
    Data
  ) {
    const datos = {
      IdUsuario,
      Data
    }
    console.log(datos)
    return http.post("/Ajuste/actualizar", datos)
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }


  Anular(
    IdUsuario,
    Data
  ) {
    const datos = {
      IdUsuario,
      Data
    }
    console.log(datos)
    return http.post("/Ajuste/anular", datos)
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }


  Finalizar(
    IdUsuario,
    Data
  ) {
    const datos = {
      IdUsuario,
      Data
    }
    console.log(datos)
    return http.post("/Ajuste/finalizar", datos)
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }

  Consultar(datos) {
    return http.get("Ajuste/consultar", { params: datos })
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }

  ConsultarDetalles(datos) {
    return http.get("Ajuste/detalles/consultar", { params: datos })
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }

  ConsultarAjusteDetalleReporte(datos) {
    return http.get("Ajuste/detalles/consultar/reporte", { params: datos })
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }
}


export default new AjusteServices();